import { validateField } from "../../helpers/ValidateField";

const getFields = (smsValidation) => {
  const initialFields = [
    [
      {
        id: "address",
        type: "address",
        value: "",
        error: false,
        required: true,
        validator: (value) => validateField({ type: "address", required: true }, value),
      },
      {
        type: "hidden",
        id: "latitude",
        value: "",
      },
      {
        type: "hidden",
        id: "longitude",
        value: "",
      },
      {
        id: "assetType",
        options: ["apartment", "house"],
        required: true,
        type: "select",
        value: null,
        error: false,
        helperText: "",
        validator: (value) => validateField({ required: true }, value),
      },
      {
        id: "condition",
        options: ["new", "used", "renovated", "inConstruction", "forRecovery", "ruin"],
        required: true,
        type: "select",
        value: null,
        error: false,
        helperText: "",
        validator: (value) => validateField({ required: true }, value),
      },
      {
        id: "grossArea",
        required: true,
        type: "number",
        value: undefined,
        error: false,
        helperText: "",
        validator: (value) =>
          validateField({ type: "number", max: 2000.0, min: 2.0, required: true }, value),
      },
      {
        id: "numberOfRooms",
        // options: ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12", "13"],
        options: ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9", "10"],
        required: true,
        type: "select",
        value: null,
        error: false,
        helperText: "",
        validator: (value) => validateField({ required: true }, value),
      },
      {
        id: "energyEfficiency",
        options: ["A", "A+", "B", "B-", "C", "D", "E", "F", "G", "NC"],
        required: false,
        type: "select",
        value: null,
        error: false,
        helperText: "",
      },
      {
        id: "numberOfWcs",
        options: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9],
        required: false,
        type: "select",
        value: null,
        error: false,
        helperText: "",
      },
    ],
    [
      { id: "airConditioning", type: "chip", value: false },
      { id: "centralHeating", type: "chip", value: false },
      { id: "equippedKitchen", type: "chip", value: false },
      { id: "suite", type: "chip", value: false },
      { id: "wardrobe", type: "chip", value: false },
      { id: "elevator", type: "chip", value: false },
      /*  { id: "storage", type: "chip", value: false }, */
      { id: "alarm", type: "chip", value: false },
      { id: "videoSecurity", type: "chip", value: false },
      /*   { id: "fireplace", type: "chip", value: false }, */
      { id: "gym", type: "chip", value: false },
      /*    { id: "pantry", type: "chip", value: false }, */
      { id: "doubleWindows", type: "chip", value: false },
      { id: "electricBlinds", type: "chip", value: false },
      { id: "goodLightExposure", type: "chip", value: false },
      { id: "cityView", type: "chip", value: false },
      { id: "seaView", type: "chip", value: false },
      { id: "basement", type: "chip", value: false },
      { id: "balcony", type: "chip", value: false },
      { id: "terrace", type: "chip", value: false },
      { id: "closedGarage", type: "chip", value: false },
      { id: "garden", type: "chip", value: false },
      { id: "pool", type: "chip", value: false },
      { id: "parking", type: "chip", value: false },
    ],
    [
      {
        id: "name",
        value: null,
        error: false,
        helperText: "",
        required: true,
        fullWidth: true,
        validator: (value) => validateField({ type: "name", required: true }, value),
      },
      {
        id: "email",
        value: null,
        error: false,
        helperText: "",
        required: true,
        fullWidth: true,
        validator: (value) => validateField({ type: "email", required: true }, value),
      },
      {
        id: "phone",
        type: "phone",
        value: null,
        error: false,
        helperText: "",
        required: true,
        fullWidth: true,
        validator: (value) => validateField({ type: "phone", required: true }, value),
      },
      {
        id: "leadType",
        options: ["sell"],
        required: false,
        type: "radio",
        value: "buy",
        error: false,
        helperText: "",
        fullWidth: true,
      },
    ],
  ];

  if (smsValidation) {
    initialFields.push([
      {
        id: "smsValidation",
        type: "smsValidation",
        value: "",
        required: true,
        error: false,
        helperText: "",
        validator: (value) =>
          validateField(
            { required: true, type: "smsCode" },
            value.replace(/\s/g, "").replace(/_/g, "")
          ),
      },
    ]);
  }

  return initialFields;
};

export default getFields;
