import React from "react";
import { DialogContent, TextField, MenuItem, Grid, Chip } from "@material-ui/core";
import { geocodeByAddress, getLatLng } from "react-places-autocomplete";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import AutoCompleteLocation from "../AutoCompleteLocation";
import RadioGroup from "@material-ui/core/RadioGroup";
import MuiPhoneNumber from "material-ui-phone-number";
import FormLabel from "@material-ui/core/FormLabel";
import NumberFormat from "react-number-format";
import Radio from "@material-ui/core/Radio";
import NoSsr from "@material-ui/core/NoSsr";
import styled from "styled-components";

const Content = (props) => {
  const { step, fields, copy, setFields, lang } = props;

  const validateLatLng = (latLng) => {
    if (
      latLng.lat > 42.14590072631836 ||
      latLng.lat < 32.63460159301758 ||
      latLng.lng > -6.234419822692871 ||
      latLng.lng < -31.264799118041992
    )
      return {
        error: true,
        helperText: copy.invalidLatLng,
      };
    return {
      error: false,
      helperText: "",
    };
  };

  const clearAddress = () => {
    let updateFields = [...fields[step]];

    updateFields[0].value = "";
    updateFields[0].error = false;
    updateFields[0].helperText = "";

    updateFields[1].value = "";
    updateFields[2].value = "";

    setFields({ ...fields });
  };

  const setAddress = (latLng, address) => {
    const validAddress = validateLatLng(latLng);
    let updateFields = [...fields[step]];

    updateFields[0].value = address;
    updateFields[0].error = validAddress.error;
    updateFields[0].helperText = validAddress.helperText;

    updateFields[1].value = latLng.lat;
    updateFields[2].value = latLng.lng;

    setFields({ ...fields });
  };

  const handleSelectChange = (e, field) => {
    const updateFields = [...fields[step]];

    const idx = fields[step].findIndex((obj) => obj.id == field.id);

    updateFields[idx].value = e.target.value;
    updateFields[idx].error = false;
    updateFields[idx].helperText = "";

    setFields({ ...fields });
  };

  const renderField = (field) => {
    const { type } = field;

    switch (type) {
      case "address":
        // Only needed if using map
        // const zoom = fields[step][2] ? 15 : 8;
        // const location = fields[step][1].value &&
        // fields[step][2].value && {
        //   latitude: fields[step][1].value,
        //   longitude: fields[step][2].value,
        // };

        return (
          <Grid item xs={12}>
            <AutoCompleteLocation
              lang={lang}
              onChange={(address) => {
                if (!address) {
                  clearAddress();
                  return;
                }
                return geocodeByAddress(address)
                  .then((results) => getLatLng(results[0]))
                  .then((latLng) => {
                    setAddress(latLng, address);
                  });
              }}
              helperText={field.helperText || " "}
              address={field.value}
              error={field.error}
            />
          </Grid>
        );

      case "chip":
        return (
          <ChipSection
            key={field.id}
            item
            xs={6}
            sm={4}
            onClick={() => {
              let updateFields = [...fields[step]];
              let idx = fields[step].findIndex((obj) => obj.id == field.id);
              updateFields[idx].value = !updateFields[idx].value;
              setFields({ ...fields });
            }}
          >
            <Chip
              label={copy.labels[field.id]}
              variant="outlined"
              className="chip"
              classes={{
                root: field.value ? "selected" : "not-selected",
              }}
            />
          </ChipSection>
        );

      case "select":
        return (
          <Grid item sm={field.fullWidth ? 12 : 6} xs={12} key={field.id}>
            <TextFieldSection>
              <TextField
                onChange={(e) => handleSelectChange(e, field)}
                label={copy.labels[field.id]}
                helperText={field.helperText}
                required={field.required}
                className="text-field"
                value={field.value}
                error={field.error}
                variant="outlined"
                select
              >
                {field.options.map((item) => {
                  return (
                    <MenuItem value={item} key={field.id}>
                      {copy.options[field.id] ? copy.options[field.id][item] : item}
                    </MenuItem>
                  );
                })}
              </TextField>
            </TextFieldSection>
          </Grid>
        );

      case "radio":
        return (
          <Grid item xs={12}>
            <FormLabel component="legend">{copy.interestedIn}</FormLabel>
            <RadioGroup
              value={field.value}
              onChange={(e, v) => {
                let updateFields = [...fields[step]];
                let idx = fields[step].findIndex((obj) => obj.id == field.id);
                updateFields[idx].value = v;
                setFields({ ...fields });
              }}
            >
              <Grid container>
                {field.options.map((option) => (
                  <FormControlLabel
                    value={option}
                    control={<Radio />}
                    label={copy.options.leadType[option]}
                    labelPlacement="end"
                  />
                ))}
              </Grid>
            </RadioGroup>
          </Grid>
        );

      case "phone":
        return (
          <Grid item sm={field.fullWidth ? 12 : 6} xs={12} key={field.id}>
            <NoSsr>
              <MuiPhoneNumber
                data-cy="user-phone"
                defaultCountry={"pt"}
                onlyCountries={["pt", "gb", "es", "it"]}
                countryCodeEditable={false}
                variant="outlined"
                label={copy.labels[field.id]}
                onChange={(v) => {
                  let updateFields = [...fields[step]];
                  let idx = fields[step].findIndex((obj) => obj.id == field.id);
                  updateFields[idx].value = v;
                  updateFields[idx].error = false;
                  updateFields[idx].helperText = null;
                  setFields({ ...fields });
                }}
                required={field.required}
                value={field.value}
                error={field.error}
                disabled={field.disabled}
                helperText={field.helperText && field.helperText}
                fullWidth
              />
            </NoSsr>
          </Grid>
        );

      case "smsValidation":
        const phoneNumber = fields[2][2].value ? fields[2][2].value : "...";
        return (
          <Grid item xs>
            <Code container error={field.error}>
              {copy.enterCode}: {phoneNumber}
            </Code>
            <Grid item xs>
              <Grid container>
                <NumberFormat
                  className="sms-validation-field"
                  allowEmptyFormatting
                  format="# # # #"
                  mask="_"
                  onChange={(e, v) => {
                    let updateFields = [...fields[step]];
                    let idx = fields[step].findIndex((obj) => obj.id === field.id);
                    updateFields[idx].value = e.target.value;
                    updateFields[idx].error = false;
                    setFields({ ...fields });
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
        );

      case "hidden":
        return null;
      default:
        return (
          <Grid item sm={field.fullWidth ? 12 : 6} xs={12} key={field.id}>
            <TextFieldSection>
              <TextField
                variant="outlined"
                type={field.type}
                className="text-field"
                label={copy.labels[field.id]}
                onChange={(e) => {
                  let updateFields = [...fields[step]];
                  let idx = fields[step].findIndex((obj) => obj.id == field.id);
                  updateFields[idx].value = e.target.value;
                  updateFields[idx].error = false;
                  updateFields[idx].helperText = null;
                  setFields({ ...fields });
                }}
                onBlur={(e) => {
                  if (!e.target.value && !field.required) return;
                  let updateFields = [...fields[step]];
                  let idx = fields[step].findIndex((obj) => obj.id == field.id);
                  updateFields[idx].value = e.target.value;
                  if (field.validator && e.target.value) {
                    const validated = field.validator(e.target.value);
                    updateFields[idx].value = validated.value;
                    updateFields[idx].error = validated.error || false;
                    updateFields[idx].helperText = validated.helperText.trim() || null;
                  }
                  setFields({ ...fields });
                }}
                required={field.required}
                value={field.value}
                error={field.error}
                disabled={field.disabled}
                helperText={field.helperText && field.helperText}
              />
            </TextFieldSection>
          </Grid>
        );
    }
  };

  return (
    <DialogContentCustom>
      <Grid container spacing={2} alignItems="center" justify="center">
        {fields[step].map((field) => renderField(field))}
      </Grid>
    </DialogContentCustom>
  );
};

const DialogContentCustom = styled(DialogContent)`
  padding: 15px 10px;
  min-height: 360px;
  @media (max-width: 600px) {
    margin-bottom: 70px;
    min-height: 10px;
  }

  .sms-validation-container {
    height: 350px;
  }

  .sms-validation-field {
    overflow-x: hidden;
    text-align: center;
    padding-top: 60px;
    font-size: 7rem;
    border: none;
    @media (max-width: 960px) {
      width: 100%;
    }
    @media (max-width: 600px) {
      font-size: 4rem;
    }
  }
`;

const TextFieldSection = styled.section`
  .text-field {
    width: 100%;
    padding-bottom: 5px;
    margin: 0px;
  }
`;

const ChipSection = styled(Grid)`
  @media (max-width: 600px) {
    padding: 8px 0px !important;
  }

  .selected {
    background: #000;
    width: 120px;
    color: white;
    border: 0px;
    &:hover {
      cursor: pointer;
    }
  }

  .not-selected {
    background: #f6f7f7;
    width: 120px;
    border: 0px;
    &:hover {
      background-color: rgba(70, 85, 95, 0.1);
      cursor: pointer;
    }
  }

  .chip {
    height: 30px;
  }
`;

const Code = styled(Grid)`
  color: ${(p) => (p.error ? "red" : "")};
`;

export default Content;
