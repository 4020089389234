const styles = (theme) => {
  return {
    container: {
      minHeight: "100vh",
      display: "flex",
      position: "center center",
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
    },
    innerContainer: {
      backgroundColor: "transparent",
      transition: "background 0.3s, border-radius 0.3s, opacity 0.3s",
    },
    content: {
      maxWidth: "1200px",
      padding: "25px",
      [theme.breakpoints.only("xs")]: {
        padding: "5px",
      },
    },
    leftContent: {
      padding: "0px 120px 0px 0px",
      marginBottom: "25px",
      [theme.breakpoints.only("xs")]: {
        margin: "2.5%",
        padding: "2.5%",
      },
    },
    compensateHeader: {
      [theme.breakpoints.up("md")]: {
        minHeight: 100,
      },
    },
    logoDesktop: {
      width: "20%",
      maxWidth: "450px",
      objectFit: "contain",
      paddingBottom: 10,
      [theme.breakpoints.only("xs")]: {
        width: "20%",
        paddingBottom: 10,
      },
    },
    logoDesktopWithUser: {
      position: "absolute",
      top: 0,
      left: 0,
      width: "10%",
      maxWidth: "80px",
      objectFit: "contain",
      padding: "25px",
      [theme.breakpoints.down("lg")]: {
        maxWidth: "80px",
      },
      [theme.breakpoints.down("sm")]: {
        position: "relative",
        width: "100%",
        padding: 0,
        paddingBottom: 10,
      },
    },
    card: {
      boxShadow: "0px 0px 30px 5px rgba(1, 87, 140, 0.2)",
      borderRadius: "10px",
      padding: "25px",
      maxWidth: "450px",
      [theme.breakpoints.down("sm")]: {
        maxWidth: "100%",
      },
      [theme.breakpoints.only("xs")]: {
        margin: "5%",
        padding: "5%",
      },
    },
    title: {
      color: "rgba(255, 255, 255, 0.9)",
      fontSize: 20,
      fontWeight: 600,
    },
    divider: {
      width: 100,
      paddingLeft: 0,
      height: "1px",
      marginTop: "15px",
      marginBottom: "15px",
      background: "white",
    },

    subTitle: {
      fontSize: "5rem",
      color: "rgba(255, 255, 255, 1)",
      fontWeight: 600,
      lineHeight: 1.2,
      [theme.breakpoints.down("md")]: {
        fontSize: "4rem",
      },
      [theme.breakpoints.only("xs")]: {
        fontSize: "3rem",
      },
    },
    disclaimer: {
      padding: "20px 10px",
      textAlign: "justify",
      fontSize: 14,
      color: "rgba(255, 255, 255, 0.8)",
      [theme.breakpoints.only("xs")]: {
        top: "0vh",
        padding: "2.5%",
      },
    },
    disclaimerContainer: {
      maxWidth: "500px",
      [theme.breakpoints.down("sm")]: {
        maxWidth: "100%",
      },
    },
    textLink: {
      color: "white",
    },
    customTitle: {
      paddingTop: "40px",
      textAlign: "left",
      color: "#000",
      fontSize: 20,
      lineHeight: 1,
    },
    cSubTitle: {
      textAlign: "left",
      fontSize: 15,
      opacity: 0.5,
      paddingTop: 5,
      paddingBottom: 50,
    },
  };
};

export default styles;
