import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import Requester from "./helpers/Requester_v2";
import LeadsERA from "./screens/LeadsERA";
import theme from "./theme/ERA/era";
import { MuiThemeProvider } from "@material-ui/core/styles";

import * as serviceWorker from "./serviceWorker";

const alfredoAPIRequester = new Requester(`${process.env.REACT_APP_SERVER}`);
export { alfredoAPIRequester };

ReactDOM.render(
  <MuiThemeProvider theme={theme}>
    <LeadsERA />
  </MuiThemeProvider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about serv    ice workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
