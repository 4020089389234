const COMPANY_NAME = "Alfredo Leads";

export const COPY = {
  languages: ["pt", "en"],
  pt: {
    agent: "O seu consultor:",
    agency: "A sua agência:",
    content: {
      title: "UMA MÁQUINA  A VENDER CASAS",
      subTitle: "SAIBA O VALOR DO SEU IMÓVEL",
    },
    modal: {
      stepText: {
        title: null,
        titles: [
          "PREENCHA O FORMULÁRIO",
          "PREENCHA O FORMULÁRIO",
          "PREENCHA O FORMULÁRIO",
          "OBRIGADO PELO SEU INTERESSE!",
        ],
        subTitle: null,
        subTitles: [null, null, null, null, "BREVEMENTE ENTRAREMOS EM CONTACTO."],
      },
      form: [
        {
          title: "Localização",
          formFields: [
            { name: "Morada ou Cód. Postal", id: "address" },
            {
              id: "assetType",
              name: "Tipo de imóvel",
              options: { house: "Moradia", apartment: "Apartamento" },
            },
            {
              name: "Condição",
              id: "condition",
              options: {
                forRecovery: "Para recuperação",
                inConstruction: "Em construção",
                new: "Novo",
                renovated: "Renovado",
                ruin: "Em ruína",
                used: "Usado",
              },
            },
            {
              name: "Quartos",
              id: "numberOfRooms",
            },
            {
              name: "Área bruta",
              id: "grossArea",
            },
          ],
          button: "Etapa seguinte",
          backButton: "Anterior",
        },
        {
          title: "Informação adicional",
          characteristics: true,
          formFields: {
            storage: "Arrecadação",
            doubleWindows: "Vidros duplos",
            centralHeating: "Aquecimento central",
            alarm: "Alarme",
            videoSecurity: "Vídeo-porteiro",
            elevator: "Elevador",
            seaView: "Vista de mar",
            balcony: "Varanda",
            garden: "Jardim",
            basement: "Cave",
            suite: "Suite",
            closedGarage: "Garagem",
            equippedKitchen: "Cozinha equipada",
            electricBlinds: "Estores elétricos",
            pool: "Piscina",
            terrace: "Terraço",
            fireplace: "Lareira",
            airConditioning: "Ar condicionado",
          },
          button: "Etapa seguinte",
          backButton: "Anterior",
        },
        {
          title: "Informação pessoal",
          submit: true,
          formFields: [
            {
              id: "name",
              name: "Nome",
              fullWidth: true,
            },
            {
              id: "email",
              name: "Endereço de e-mail",
              fullWidth: true,
            },
            {
              id: "phone",
              name: "Número de telefone",
              fullWidth: true,
            },
          ],
          button: "Receber estimativa",
          backButton: "Anterior",
        },
        {
          title: "Resultado da estimativa",
          errorMessage: "Ocorreu um erro, por favor tente novamente mais tarde",
          loadingMessage: "Estimativa em curso, por favor aguarde...",
          predictText: "-",
          assetType: { house: "Moradia", apartment: "Apartamento" },
        },
      ],
      priceInterval: "Valor estimado entre:",
      errorMessages: {
        required: "Campo obrigatório",
        address: "A morada introduzida tem que ser em Portugal",
        limits: {
          start: "O valor introduzido tem de ser superior a ",
          end: " e inferior a ",
        },
        min: "O valor introduzido tem de ser superior a",
        max: "O valor introduzido tem de ser inferior a",
        invalid: "Valor inválido",
        email: "Email inválido",
        phone: "Número de telefone inválido",
      },
    },
    customComponent: {
      title: "Dê o salto para a casa certa",
      subTitle: "Comece a viver na nova ERA. Será contactado em breve para saber mais!",
    },
    disclaimer: `Ao submeter o formulário está a autorizar a ERA a guardar os seus dados para o informar sobre imóveis de acordo com a sua`,
    linkText: "Política de Privacidade",
    link: "https://www.era.pt/quem-somos/default.aspx?seccao=condicoes&idioma=pt",
  },
  en: {
    agent: "Your agent:",
    agency: "Your agency:",
    content: {
      title: "A MACHINE SELLING HOUSES",
      subTitle: "GET TO KNOW YOUR ASSET VALUE",
    },
    modal: {
      stepText: {
        title: null,
        titles: [
          "FILL IN THE FORM",
          "FILL IN THE FORM",
          "FILL IN THE FORM",
          "THANK YOUR FOR YOUR INTEREST!",
        ],
        subTitle: null,
        subTitles: [null, null, null, null, "WE WILL CONTACT YOU SOON."],
      },
      form: [
        {
          title: "Address",
          formFields: [
            { name: "Address or Zipcode", id: "address" },
            {
              id: "assetType",
              name: "Asset type",
              options: { house: "House", apartment: "Apartment" },
            },
            {
              name: "Condition",
              id: "condition",
              options: {
                new: "New",
                used: "Used",
                renovated: "Renovated",
                inConstruction: "New construction",
                forRecovery: "For recovery",
                ruin: "Ruin",
              },
            },
            {
              name: "Number of rooms",
              id: "numberOfRooms",
            },
            {
              name: "Gross area",
              id: "grossArea",
            },
          ],
          button: "Next step",
          backButton: "Back",
        },
        {
          title: "Additional information",
          characteristics: true,
          formFields: {
            storage: "Storage",
            doubleWindows: "Double windows",
            centralHeating: "Central heating",
            alarm: "Alarm",
            videoSecurity: "Video security",
            elevator: "Elevator",
            seaView: "Sea view",
            balcony: "Balcony",
            garden: "Garden",
            basement: "Basement",
            suite: "Suite",
            closedGarage: "Closed garage",
            equippedKitchen: "Equipped kitchen",
            electricBlinds: "Electric blinds",
            pool: "Pool",
            terrace: "Terrace",
            fireplace: "Fireplace",
            airConditioning: "Air conditioning",
          },
          button: "Next step",
          backButton: "Back",
        },
        {
          title: "Personal information",
          submit: true,
          formFields: [
            {
              id: "name",
              name: "Name",
              fullWidth: true,
            },
            {
              id: "email",
              name: "Email address",
              fullWidth: true,
            },
            {
              id: "phone",
              name: "Phone number",
              fullWidth: true,
            },
          ],
          button: "Submit form",
          backButton: "Back",
        },
        {
          title: "Estimative results",
          errorMessage: "An error has occurred, please try again later",
          loadingMessage: "Loading estimatives, please wait...",
          predictText: "-",
          assetType: { house: "House", apartment: "Apartment" },
        },
      ],
      priceInterval: "Price estimate between:",
      errorMessages: {
        required: "Mandatory field",
        address: "Address must be located in Portugal",
        limits: {
          start: "Number must be above ",
          end: " and inferior to ",
        },
        min: "Number must be above",
        max: "Number must be inferior to",
        invalid: "Invalid value",
        email: "Invalid email",
        phone: "Invalid phone number",
      },
    },
    customComponent: {
      title: "Make the leap to the right house",
      subTitle: "Start living a new ERA. You will be contacted shortly to know more!",
    },
    disclaimer: `By subscribing to the services provided by this website, you are authorizing ERA to save your data to inform you about properties in accordance with its`,
    linkText: "Privacy Policy",
    link: "https://www.era.pt/quem-somos/default.aspx?seccao=condicoes&idioma=pt",
  },
};
